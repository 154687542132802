import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Seo from '../components/seo'

import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'

import StarIcon from '@mui/icons-material/Star'
import InfoIcon from '@mui/icons-material/Info'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Done from '@mui/icons-material/Done'
import ArrowForward from '@mui/icons-material/ArrowForward'


const FeaturePage = () => {
  const [features, setFeatures] = React.useState(true)
  const [info, setInfo] = React.useState(true)

  function handleClick(id: string) {
    switch(id) {
      case "features":
        setFeatures(!features);
        break;
    case "info":
    default:
        setInfo(!info);
        break;

    }
  }
  return(
  <>
    <Seo title="Features" />
  <Grid container spacing={3} justifyContent="center">
    <Grid item xs={2}>
      <div style={{ maxWidth: `100px`, marginBottom: `1.45rem` }}>
        <StaticImage width={160} alt="logo" src="../images/wernerdigital-hosted.png" />

      </div>
    </Grid>
    <Grid item xs={8}>
      <h1>Indyware e-commerce suite</h1>
      <h5>
        Simple, dependable tools for ecommerce.
      </h5>
    </Grid>
  </Grid>
  <Divider />
  <List
    component="nav"
  >
  <ListItem id="features" button onClick={() => handleClick("features")}>
    <ListItemIcon>
      <StarIcon />
    </ListItemIcon>
    <ListItemText primary="Features" />
    {features ? <ExpandLess /> : <ExpandMore />}
  </ListItem>
  <Collapse in={!features} timeout="auto" unmountOnExit>
    <List component="div" disablePadding>
      <ListItem button >
        <ListItemIcon><Done /></ListItemIcon>
        <ListItemText primary="Merge reports/marketplaces" />
      </ListItem>
      <ListItem button >
        <ListItemIcon><Done /></ListItemIcon>
        <ListItemText primary="Quickly monitor changes" />
      </ListItem>
      <Link to="/features">
      <ListItem button >
        <ListItemIcon><ArrowForward /></ListItemIcon>
        <ListItemText primary="Full Feature List" />
      </ListItem>
      </Link>
    </List>
  </Collapse>
  <ListItem button onClick={() => handleClick("info")}>
    <ListItemIcon>
      <InfoIcon />
    </ListItemIcon>
    <ListItemText primary="Tools" />
    {info ? <ExpandLess /> : <ExpandMore />}
  </ListItem>
  <Collapse in={!info} timeout="auto" unmountOnExit>
    <List component="div" disablePadding>
      <ListItem button >
        <ListItemIcon><Done /></ListItemIcon>
        <ListItemText primary="Pricewatcher for Amazon marketplaces" />
      </ListItem>
      <ListItem button >
        <ListItemIcon><Done /></ListItemIcon>
        <ListItemText primary="Reports Manager" />
      </ListItem>
    </List>
  </Collapse>
</List>
  </>
)}

export default FeaturePage
